export const cel_mask = value => {
    if (!value) 
      return value

    const num = value.replace(/[^\d]/g, '')
    if (num.length <= 2)
        return num

    if (num.length <= 3)
        return `(${num.slice(0, 2)}) ${num.slice(2)}`

    if (num.length <= 7)
        return `(${num.slice(0, 2)}) ${num.slice(2,3)}.${num.slice(3,7)}`

    return `(${num.slice(0, 2)}) ${num.slice(2,3)}.${num.slice(3,7)}-${num.slice(7,11)}`

}


export const cpf_mask = value => {
    if (!value) 
      return value

    const num = value.replace(/[^\d]/g, '')
    if (num.length <= 3)
        return num

    if (num.length <= 6)
        return `${num.slice(0, 3)}.${num.slice(3)}`

    if (num.length <= 9)
        return `${num.slice(0, 3)}.${num.slice(3,6)}.${num.slice(6)}`

    return `${num.slice(0, 3)}.${num.slice(3,6)}.${num.slice(6,9)}-${num.slice(9,11)}`

}


export const cnpj_mask = value => {
    if (!value) 
      return value

    const num = value.replace(/[^\d]/g, '')
    if (num.length <= 2)
        return num

    if (num.length <= 5)
        return `${num.slice(0, 2)}.${num.slice(2)}`

    if (num.length <= 8)
        return `${num.slice(0, 2)}.${num.slice(2,5)}.${num.slice(5)}`

    if (num.length <= 12)
        return `${num.slice(0, 2)}.${num.slice(2,5)}.${num.slice(5,8)}/${num.slice(8)}`

    return `${num.slice(0, 2)}.${num.slice(2,5)}.${num.slice(5,8)}/${num.slice(8,12)}-${num.slice(12,14)}`

}

export const cep_mask = value => {
    if (!value) 
      return value

    const num = value.replace(/[^\d]/g, '')
    if (num.length <= 5)
        return num

    return `${num.slice(0, 5)}-${num.slice(5,8)}`
}



export const ref_mask = value => {
    if (!value)
        return value
    return value.replace(/[^A-Za-z0-9\-#.$]/g, '').toUpperCase()
}
