import act from '../main/action-const'

const INITIAL_STATE = { 
}

export default function(state = INITIAL_STATE, action) {

    switch (action.type) {

        case act.DASH_CONTADOR_CARREGADO:
            return { ...state, ...action.payload}
            
        default:
            return state
    }
}
