import React, { useState, useEffect, useRef } from 'react';

const CountdownTimer = ({ endTime, onEnd }) => {
    const [time, setTime] = useState(Math.trunc((endTime - new Date()) / 1000));

    const ref = useRef(time);

    useEffect(() => {
        if (time <= 0) {
            clearInterval(ref.current);
            if (onEnd)
                onEnd();
        } else {
            ref.current = setInterval(() => {
                let new_time = Math.trunc((endTime - new Date()) / 1000);
                if (Number.isInteger(new_time))
                    setTime((currentTime) => (new_time));
            }, 1000)
        }
        return () => {
            clearInterval(ref.current);
        };
    }, [time, endTime, onEnd]);

    if (time <= 0 ) return <></>;

    return (
        <>
            <span>{time > 0 ? Math.floor(time / 3600) : 0}</span>:
            <span>{time > 0 ? Math.floor(time / 60) % 60 : 0}</span>:
            <span>{time > 0 ? time % 60 : 0}</span>
        </>
    );
}

export default CountdownTimer;