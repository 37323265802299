import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ContentSection, Row } from '../common/layout'
import PageSetup from '../main/page-setup'
import CardImageTop from '../common/widget/card-image-top'

import config from '../main/config'
import { listar } from './proximo-curso-action'
import { CalloutInfo } from '../common/msg/callout'


class ProximoCurso extends Component {
   
    componentDidMount() {
        this.props.listar([])
    }

    renderCursos(dados) {
        if (dados.length === 0) {
            return (
                <CalloutInfo title="Por enquanto você não tem cursos pendentes!">
                </CalloutInfo>
            )
        }


        const cfg = config()

        return dados.map(vo => {
            return(
                <CardImageTop
                    key={'curso'+vo['id_curso']} 
                    col_class="col-md-3"
                    minHeightContent={'150px'}
                    route={`/proximo-curso-info/${vo['id_curso']}/${vo['id_grade_item']}`}
                    image={cfg.CDN_PATH +'curso.png'}
                    color_class={ 'bg-aqua-active' }
                    title={vo['titulo']}
                    subtitle={vo['subtitulo']}
                >  
                {vo['media']&&(<><br /><h4><b>Nota: {vo['media']}</b></h4></>)}
                </CardImageTop>
            )
        })
    }

    render_view() {
        return(
            <PageSetup title='Próximos Cursos'>
                <ContentSection>
                    <Row>
                        {this.renderCursos(this.props.proximo_curso.lista)}
                    </Row>
                </ContentSection>
            </PageSetup>
        )
    }

    render() {
        return this.render_view()
    }

}

const mapStateToProps = state => ({proximo_curso: state.proximoCurso})
const mapDispatchToProps = dispatch => bindActionCreators({listar}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(ProximoCurso)

