import { transmitir } from '../sys/transmitir'

import act from '../main/action-const'
import { obter as obter_aula } from '../minhas-aulas/minhas-aulas-action'

const ROTA = 'quest-resp'

export const clear = () => {
    return dispatch => dispatch({ type: act.RESP_CLEAR })
}

export const finished = () => {
    return dispatch => dispatch({ type: act.RESP_FINICHED })
}


export const obter = (id_resp) => {
    const vo = []
    vo['id_resp'] = id_resp
    return transmitir(vo, 'get', ROTA,
        (payload) => ({ type: act.RESP_CARREGADO, payload: payload[0] ?? {} })
    )
}


export const confirmar = (dados) => {
    return transmitir(dados, 'post', ROTA,
        (payload) => ([
            { type: act.RESP_NA, payload: payload },
            obter(dados['id_resp'])
        ])
    )
}


export const anterior = (dados) => {
    let vo = []
    vo['id_quest'] = dados['id_quest']
    vo['id_resp'] = dados['id_resp']

    return transmitir(vo, 'get', ROTA + '-anterior',
        (payload) => ([
            { type: act.RESP_NA, payload: payload },
            obter(vo['id_resp'])
        ])
    )
}


export const proximo = (dados) => {
    let vo = []
    vo['id_quest'] = dados['id_quest']
    vo['id_resp'] = dados['id_resp']

    return transmitir(vo, 'get', ROTA + '-proximo',
        (payload) => ([
            { type: act.RESP_NA, payload: payload },
            obter(vo['id_resp'])
        ])
    )
}


export const finalizar = (dados) => {
    return transmitir(dados, 'post', ROTA + '-finalizar',
        (payload) => ([
            { type: act.RESP_NA, payload: payload },
            obter(dados['id_resp'])
        ])
    )
}

export const finalizar_reload_aula = (dados, id_inscricao, id_aula) => {
    return transmitir(dados, 'post', ROTA + '-finalizar',
        (payload) => ([
            { type: act.RESP_NA, payload: payload },
            obter(dados['id_resp']),
            obter_aula(id_inscricao, id_aula)
        ])
    )
}

export const refazer_quest = (dados, id_inscricao, id_aula) => {
    return transmitir(dados, 'post', ROTA + '-refazer',
        (payload) => ([
            { type: act.RESP_NA, payload: payload },
            obter_aula(id_inscricao, id_aula)
        ])
    )
}

export const check_uncheck = (dados) => {
    return [{ type: act.RESP_CHECK_UNCHECK, payload: dados }]
}

