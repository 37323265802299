import { transmitir } from '../sys/transmitir'

import act from '../main/action-const'

const ROTA = 'carrinho'

export const obter = () => {
    return transmitir([], 'get', ROTA,
        (payload) => ({ type: act.CARRINHO_LISTADO, payload: payload || {} })
    )
}


export const limpar = () => {
    return transmitir([], 'delete', ROTA,
        (payload) => ({ type: act.CARRINHO_CLEAR })
    )
}

export const cancelar_pedido = (id_pedido) => {
    const vo = []
    vo['id_pedido'] = id_pedido
    return transmitir(vo, 'delete', ROTA + '-pedido',
        (payload) => ({ type: act.CARRINHO_CLEAR })
    )
}


export const obter_desconto = (cupom, formapg) => {
    const vo = {}
    vo['cupom'] = cupom
    vo['formapg'] = formapg
    return transmitir(vo, 'post', ROTA + '-cupom',
        (payload) => ({ type: act.CARRINHO_LISTADO, payload: payload || {} }), null, true
    )
}

export const iniciar_pag = (cupom, formapg) => {
    const vo = {}
    vo['cupom'] = cupom
    vo['formapg'] = formapg
    return transmitir(vo, 'post', ROTA + '-meio-pag',
        (payload) => ({ type: act.CARRINHO_LISTADO, payload: payload || {} }), null, true
    )
}
