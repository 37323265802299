import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Anchor } from '../common/helper'

import { change_password, logout } from './login-actions'
import config from '../main/config'

import { Row, Grid } from '../common/layout'
import { Input } from '../common/form'


class LoginPage extends Component{

    componentDidMount() {
        document.body.classList.remove( config().THEME_NAME )
        document.body.classList.remove('sidebar-mini')
        document.body.classList.add('login-page')
    }

    componentWillUnmount() {
        document.body.classList.remove('login-page')
        document.body.classList.add( config().THEME_NAME )
        document.body.classList.add('sidebar-mini')
    }

    onSubmit(values) {
        const { change_password } = this.props
        change_password(values)
    }

    render() {
        const { handleSubmit } = this.props

        return (
        <div className="login-box">
            <div className="login-logo">
                <Anchor href="/">{config().NOME_SISTEMA || 'Nome Sistema'}</Anchor>
            </div>
            <div className="login-box-body">

                <h3 className="login-box-msg">Registre uma nova Senha</h3>
                <p className="login-box-msg">Informe uma nova senha, com ao menos 6 caracteres, número e letras.</p>

                <form onSubmit={handleSubmit( v => this.onSubmit(v))}>
                    <div className={`form-group has-feedback ${this.props.login_info.login_error ? 'has-error': ''}`}>
                        <Field component={Input} type="password" name="nova_senha" placeholder="Nova senha" />
                        <span className="glyphicon glyphicon-lock form-control-feedback"></span>
                    </div>
                    <div className={`form-group has-feedback ${this.props.login_info.change_error ? 'has-error': ''}`}>
                        <Field component={Input} type="password" name="confirma_senha" placeholder="Confirme a senha." />
                        <span className="glyphicon glyphicon-lock form-control-feedback"></span>
                        <span className="help-block">{this.props.login_info.change_error}</span>   
                    </div>
                    <Row>
                        <Grid cols="md-12">
                            <button type="button" className="btn btn-danger" onClick={this.props.logout}><i className="fa fa-close"></i></button>
                            <button type="submit" className="btn btn-primary pull-right">Confirmar</button>
                        </Grid>
                    </Row>

                </form>

            </div>
        </div>
        )
    }
}

LoginPage = reduxForm({
    form: 'loginForm'
})(LoginPage)
const mapStateToProps = state => ({ login_info: state.login })
const mapDispatchToProps = (dispatch) => bindActionCreators({ change_password, logout }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
