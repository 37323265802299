import React from 'react'
import { BadgeAqua, BadgeGreen, Badge } from '../common/ui/badge'
const strateg_sit = {
    '99': (left) => (<Badge left={left}>Cancelada</Badge>),
    '10': (left) => (<BadgeAqua left={left}>Pendente</BadgeAqua>),
    '20': (left) => (<BadgeGreen left={left}>Liquidado</BadgeGreen>),
    '30': (left) => (<BadgeAqua left={left}>Vencido</BadgeAqua>)
}

export function status_extrato(sit, left) {
    const f_sit = strateg_sit[sit ?? '']
    if (f_sit) {
        return f_sit(left)
    }
    return (<></>)
}
