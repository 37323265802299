import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Row } from '../common/layout'
import CardImageTop from '../common/widget/card-image-top'
import ProgressBar from '../common/widget/progress-bar'
import { BadgeRed, BadgeYellow } from '../common/ui/badge'
import { date_loc } from '../sys/normalize-location'


import config from '../main/config'
import { listar } from './meus-cursos-action'
import { meuCursoSituacao } from './meu-curso-situacao'

class MeusCursosLista extends Component {

    componentDidMount() {
        this.props.listar([])
    }

    render_prazo(dados) {
        if (dados?.expirado === '1')
            return (
                <>
                    <BadgeRed>Prazo: {date_loc(dados?.dt_prazo)}</BadgeRed>
                    <BadgeRed>Curso Expirado</BadgeRed>
                </>
            )

        if (dados?.dt_prazo && (parseInt(dados?.faltam_dias) <= 7))
            return (
                <>
                    <BadgeRed>{dados?.faltam_dias} dias</BadgeRed>
                    <BadgeRed>Prazo: {date_loc(dados?.dt_prazo)}</BadgeRed>

                </>
            )

        if (dados?.dt_prazo)
            return (
                <>
                    <BadgeYellow>{dados?.faltam_dias} dias</BadgeYellow>
                    <BadgeYellow>Prazo: {date_loc(dados?.dt_prazo)}</BadgeYellow>
                </>
            )
    }


    renderCursos(dados) {
        const cfg = config()

        return dados.map(vo => {
            return (
                <CardImageTop
                    key={'inscr' + vo['id_inscricao']}
                    col_class="col-md-4"
                    minHeightContent={'200px'}
                    route={`/meu-curso-info/${vo['id_inscricao']}`}
                    image={cfg.CDN_PATH + '/curso.png'}
                    color_class={'bg-aqua-active'}
                    title={vo['titulo']}
                >
                    {vo['sit_inscricao'] === "1" &&
                        <ProgressBar percent={vo['per_aula']} />
                    }
                    {this.render_prazo(vo)}
                    {meuCursoSituacao(vo['sit_inscricao'])}
                    {vo['media'] && (<><br /><h4><b>Nota: {vo['media']}</b></h4></>)}

                </CardImageTop>
            )
        })
    }

    render_view() {
        return (
            <Row>
                {this.renderCursos(this.props.meus_cursos.lista)}
            </Row>
        )
    }

    render() {
        return this.render_view()
    }

}

const mapStateToProps = state => ({ meus_cursos: state.meusCursos })
const mapDispatchToProps = dispatch => bindActionCreators({ listar }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(MeusCursosLista)

