import act from '../main/action-const'

const INITIAL_STATE = {}

export default function (state = INITIAL_STATE, action) {

    switch (action.type) {

        case act.RESP_CARREGADO: {
            const sec = parseFloat(action.payload?.sec_restante ?? 0)
            const end_time = new Date(new Date().getTime() + sec * 1000)
            const time_finished =
                action.payload?.limite  // tem tempo limite
                && !action.payload?.dh_fim  // não está finalizado
                && (Math.trunc((end_time - new Date()) / 1000) < 0) // esgotou o tempo do questionário
            action.payload['end_time'] = end_time
            action.payload['time_finished'] = time_finished
            return { ...INITIAL_STATE, ...action.payload }
        }

        case act.RESP_FINICHED:
            return { ...state, time_finished: true, end_time: 0 }

        case act.RESP_CHECK_UNCHECK:
            return { ...state, resp: [...action.payload] }

        case act.RESP_CLEAR:
            return { ...INITIAL_STATE }
        default:
            return state
    }
}
