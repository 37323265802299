import { initialize } from 'redux-form'
import { transmitir } from '../sys/transmitir'

import act from '../main/action-const'

const VALORES_INICIAIS = {}
const ROTA = 'meu-curso'

export const listar = (dados) => {
    let vo = []
    if (dados && dados['field'] && dados['value']) {
        vo[ dados['field'] ] = dados['value']
    }
    return transmitir(vo, 'get', ROTA + '-list',
        (payload)=> ({ type: act.MEUS_CURSOS_LISTADOS, payload: payload })
    )
}


export const obter = (id_inscricao) => {
    let vo = []
    vo['id_inscricao'] = id_inscricao
    return transmitir(vo, 'get', ROTA,
        (payload)=> ({ type: act.MEU_CURSO_CARREGADO, payload: payload[0] || {} })
    )
}


export const init = () => {
    return [
        initialize('cursoEditForm', VALORES_INICIAIS)
    ]
}
