import act from '../main/action-const'

const INITIAL_STATE = {
    pageTitle: 'Página',
    pageSubtitle: 'subtítulo',
    userImage: 'profile.jpg'
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case act.APP_DATA_UPDATE:
            return { ...state, ...action.payload }
        
        default:
            return state
    }
}

