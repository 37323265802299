import React from 'react';
import Logo from './logo'
import HeaderNavbar from './header-navbar'

export default props => (
    <header className="main-header">
        <Logo label={props.title || 'Adminstrativo'} mine_label={props.title_mini ||"Adm"} />
        <HeaderNavbar {...props}/>
    </header>
)
