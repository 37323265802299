import React, { Component } from 'react'
import { connect } from 'react-redux'

import { HashRouter } from 'react-router-dom'
import Routes from './routes'

import MainHeader from '../common/template/main-header'
import MainSidebar from '../common/template/main-sidebar'
import ContentWrapper from '../common/template/content-wrapper'
import MainFooter from '../common/template/main-footer'
// import ControlSidebar from '../common/template/control-sidebar'
import Messages from '../common/msg/messages'

import MenuPrincipal from '../menu/menu-principal'

import config from './config'
import { date_time_loc } from '../sys/normalize-location'

class App extends Component {

    render() {
        const img_perfil = (this.props.login.usuario['fotofile']) ? (this.props.login.usuario['cdn_endpoint'] + this.props.login.usuario['fotofile']) : (config().IMAGE_PATH + 'profile.jpg')
        return (
            <HashRouter>
                <MainHeader
                    title={config().NOME_SISTEMA}
                    title_mini={config().APELIDO_SISTEMA}
                    logout={this.props.logout}
                    go_pwd_change={this.props.go_pwd_change}
                    user_image={img_perfil}
                    user_name={this.props.login.usuario['username']}
                    user_dt_login={date_time_loc(this.props.login.usuario['dtlogin'])}
                />
                <MainSidebar user_image={img_perfil} user_name={this.props.login.usuario['username']}>
                    <MenuPrincipal />
                </MainSidebar>
                <ContentWrapper
                    page_title={this.props.app.pageTitle}
                    page_subtitle={this.props.app.pageSubtitle}
                >
                    <Routes />
                </ContentWrapper>
                <MainFooter year={(new Date()).getFullYear()} />
                {/*<ControlSidebar />*/}
                <Messages />
            </HashRouter>
        )
    }
}

const mapStateToProps = state => ({ app: state.app, login: state.login })
export default connect(mapStateToProps)(App)

