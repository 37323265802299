import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { BoxWidget, BoxFooterComments, BoxComments } from '../common/widget/box'

class ProximoCursoAulasList extends Component {

    renderAulas(aulas) {
        return (aulas || []).map(function (au) {
            return (
                <BoxComments key={'aula' + au['id_aula']}>
                    {au['modulo'] + '.' + au['aula'] + ' - ' + au['titulo']}
                </BoxComments>)
        }
        )
    }

    renderModulo(modulo) {
        return (
            <BoxWidget
                key={'modulo-' + modulo['id_aula']}
                title={modulo['titulo']}
                subtitle=""
            >
                <BoxFooterComments>
                    {this.renderAulas(modulo['aulas'])}
                </BoxFooterComments>
            </BoxWidget>
        )
    }

    renderModuloList(lst) {
        const modulos = (lst || []).filter(vo => vo['aula'] === '0')
        for (let mo of modulos) {
            mo['aulas'] = (lst || []).filter(vo => vo['aula'] !== '0' && vo['modulo'] === mo['modulo'])
        }
        return modulos.map(mo => {
            return this.renderModulo(mo)
        })
    }

    render() {
        return (
            <>
                {this.renderModuloList(this.props.proximo_curso?.aulas)}
            </>
        )
    }
}

const mapStateToProps = state => ({
    proximo_curso: state.proximoCurso
})
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(ProximoCursoAulasList)

