import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
    obter, finished, check_uncheck, confirmar, anterior, proximo,
    finalizar, finalizar_reload_aula, refazer_quest
} from '../quest-resp/resp-action'
import { decodeBase64 } from '../sys/base64'


import { BoxWidget, BoxBody, BoxFooter } from '../common/widget/box'
import { ButtonDanger, ButtonDefault, ButtonWarning } from '../common/ui/buttons'
import { CalloutDanger, CalloutSucess } from '../common/msg/callout'
import Modal, { modal_open } from '../common/ui/modal'

import CountdownTimer from '../common/widget/countdowntimer'

import config from '../main/config'
import './resp.css'

const LabelEscolha = (props) => {
    if (props.sele === 1 && props.corr === 1) {
        return (<span className="label label-success margin-r-5">Escolha correta</span>)
    }
    if (props.sele === 1 && props.corr === 0) {
        return (<span className="label label-danger margin-r-5">Escolha incorreta</span>)
    }
    if (props.sele === 0 && props.corr === 1) {
        return (<span className="label label-warning margin-r-5">Opção correta</span>)
    }
    if (props.sele === 0 && props.corr === 0) {
        return (<span className="label label-default margin-r-5">Opção incorreta</span>)
    }
    return <></>
}


class QuestRespShow extends Component {

    handle_change(e) {
        let resp = this.props.resp['resp'] ?? []
        resp = (resp).map(res => {
            if (res['id'] === e.target.value)
                res['selecionado'] = e.target.checked ? 1 : 0;
            return res;
        })
        this.props.check_uncheck(resp)
    }


    handle_confirmar() {
        const resp = (this.props.resp['resp'] || []).map(res => {
            return {
                id: res['id'],
                selecionado: res['selecionado'],
            }
        })
        const dados = {}
        dados['id_resp'] = this.props.resp['id_resp']
        dados['id_quest'] = this.props.resp['id_quest']
        dados['resp'] = resp
        this.props.confirmar(dados)
    }

    handle_anterior() {
        const dados = {}
        dados['id_resp'] = this.props.resp['id_resp']
        dados['id_quest'] = this.props.resp['id_quest']
        this.props.anterior(dados)
    }

    handle_proximo() {
        const dados = {}
        dados['id_resp'] = this.props.resp['id_resp']
        dados['id_quest'] = this.props.resp['id_quest']
        this.props.proximo(dados)
    }


    handle_finalizar() {
        const dados = {}
        dados['id_resp'] = this.props.resp['id_resp']
        dados['id_quest'] = this.props.resp['id_quest']
        if (this.props.reload_aula) {
            this.props.finalizar_reload_aula(dados, this.props.resp['id_inscricao'], this.props.resp['id_aula'])
        } else {
            this.props.finalizar(dados)
        }
    }

    handle_refazer() {
        const dados = {}
        dados['id_resp'] = this.props.resp['id_resp']
        dados['id_quest'] = this.props.resp['id_quest']
        dados['id_aula'] = this.props.resp['id_aula']
        this.props.refazer_quest(dados, this.props.resp['id_inscricao'], this.props.resp['id_aula'])
    }



    constructor(props) {
        super(props)
        this.handle_confirmar = this.handle_confirmar.bind(this)
        this.handle_anterior = this.handle_anterior.bind(this)
        this.handle_proximo = this.handle_proximo.bind(this)
        this.handle_finalizar = this.handle_finalizar.bind(this)
        this.handle_refazer = this.handle_refazer.bind(this)
        this.handle_change = this.handle_change.bind(this)
    }


    nova_tentativa_dlg() {
        return (
            <Modal
                id='nova-tentativa-dlg'
                type='danger'
                title='Quer Fazer Nova Tentativa?'
                confirmLabel='Confirmar'
                onConfirm={() => { this.handle_refazer(); return true }}
            >
                <p>Ao fazer uma nova tentativa, a nota desta avaliação será descartada.
                    Sua nova nota será resultante da próxima tentativa, seja ela maior ou menor que a nota atual.
                </p>
                <p>
                    Deseja realmente prosseguir?
                </p>
            </Modal>
        )
    }

    nova_tentativa_quiz_dlg() {
        return (
            <Modal
                id='nova-tentativa-quiz-dlg'
                title='Refazer o Quiz'
                confirmLabel='Confirmar'
                onConfirm={() => { this.handle_refazer(); return true }}
            >
                <p>
                    Deseja prosseguir?
                </p>
            </Modal>
        )
    }

    render_info() {
        const cfg = config()
        return (
            <BoxWidget
                title={this.props.resp['titulo']}
                subtitle={this.props.resp['proposito']}>
                <BoxBody>
                    <img className="img-responsive pad" src={cfg.CDN_PATH + 'quest.png'} alt="Questionario" />
                    <h4><b>Visão Geral</b></h4>
                    <div dangerouslySetInnerHTML={{ __html: decodeBase64(this.props.resp['visao_geral']) }}></div>
                </BoxBody>
            </BoxWidget>
        )
    }

    render_quest_labels() {
        const local_style = { fontSize: "100%" }
        const resp = this.props.resp
        return (
            <>
                {!resp?.dh_fim && resp?.limite && <span className="pull-left-container">
                    <span style={local_style} className="label pull-left label-warning margin-r-5">
                        Tempo Restante: <CountdownTimer endTime={this.props.resp?.end_time} onEnd={() => this.props.finished()} />
                    </span>
                </span>}
                <span className="pull-right-container">
                    {(parseInt(this.props.resp['tentativa']) > 1) &&
                        <span style={local_style} className="label pull-right label-warning margin-r-5">Tentativa {this.props.resp['tentativa']}</span>
                    }
                    <span style={local_style} className="label pull-right label-primary margin-r-5">Pendente {this.props.resp['qt_quest'] - this.props.resp['qt_resp']}</span>
                    <span style={local_style} className="label pull-right label-primary margin-r-5">Total {this.props.resp['qt_quest']}</span>
                    <span style={local_style} className="label pull-right label-primary margin-r-5">Peso {this.props.resp['peso']}</span>
                    <span style={local_style} className="label pull-right label-primary margin-r-5">Questão nº {this.props.resp['sequencia']}</span>
                </span>
            </>
        )
    }


    render_resp(lst) {
        return lst.map((vo, idx) => {
            return (
                <blockquote key={vo['id']}>
                    <label className="resp-container">
                        {<div dangerouslySetInnerHTML={{ __html: vo['txt'] }}></div>}

                        {(!this.props.resp['dh_fim'])
                            && <input onChange={e => this.handle_change(e)} type="checkbox" value={vo['id']} checked={vo['selecionado'] === 1} />}

                        {(this.props.resp['dh_fim'])
                            && <> <LabelEscolha sele={vo['selecionado']} corr={vo['correto']} /> <input disabled type="checkbox" checked={vo['selecionado'] === 1} value={vo['id']} /> </>}

                        <span className="resp-checkmark"></span>
                    </label>
                </blockquote>
            )
        })
    }


    render_quest() {
        const quiz = this.props.quiz
        if (this.props.resp?.time_finished) {
            return (
                <BoxWidget
                    title={"Limite de tempo da avaliação terminado."}
                    subtitle="">
                    <BoxBody>
                        <CalloutDanger title="Atenção!" minHeight="350px">
                            <h3>Limite de tempo para responder o questionário terminou.</h3>
                            <h3>Por favor, clique no botão Finalizar Questionário logo abaixo para prosseguir.</h3>
                        </CalloutDanger>
                        <BoxFooter>
                            <ButtonWarning align_class="btn-lg"
                                onClick={this.handle_finalizar} >Finalizar {quiz ? 'Quiz' : 'Questionário'}</ButtonWarning>
                        </BoxFooter>

                    </BoxBody>
                </BoxWidget>
            )
        }

        const quest_style = {
            marginTop: "12px",
            borderTop: "5px",
            borderLeft: "5px solid #fff",
            paddingLeft: "5px"
        }
        const resp = this.props.resp
        const pode_finalizar = ((resp['qt_quest'] - resp['qt_resp']) === 0)
            && (!this.props.resp['dh_fim'])
        const pode_refazer = (resp['dh_fim']
            && (parseInt(resp?.tentativa) < parseInt(resp?.repetir_limite))
            && ((parseFloat(resp?.peso_geral) === 0) || parseFloat(resp?.nota) < parseFloat(resp?.media_aprovacao))
            && (
                parseInt(resp?.sit_inscricao) === 1 // inscrito
                || (parseInt(resp?.sit_inscricao) === 10 // em recuperação
                    && parseInt(resp?.tipo) === 35 // questionário de recuperação 
                )
            )
        )

        return (
            <BoxWidget
                title={resp['dh_fim']
                    ? "Resultado " + (quiz ? 'do Quiz: ' : 'da avaliação: ') + resp['nota'] + ((resp?.peso_geral ?? 0) > 0 ? ' - Peso: ' + resp?.peso_geral : '')
                    : "Marque a(s) alternativa(s) correta(s) e confirme."}
                subtitle="">

                <BoxBody>
                    {this.render_quest_labels()}
                    <div className="text-center clearfix" />
                    <h4>{quiz ? '- Quiz - ' + resp?.titulo : resp?.titulo}</h4>
                    <blockquote style={quest_style}>
                        <div dangerouslySetInnerHTML={{ __html: this.props.resp['quest'] }}></div>
                        {/*<b>{this.props.resp['quest']}</b>*/}
                    </blockquote>

                    <div className="form-group">
                        {this.render_resp(this.props.resp['resp'] || [])}
                    </div>

                    {(this.props.resp['dh_fim'] && this.props.resp['pontos'] === 0)
                        && <CalloutDanger title="Você errou!"></CalloutDanger>
                    }
                    {(this.props.resp['dh_fim'] && this.props.resp['pontos'] >= 1)
                        && <CalloutSucess title="Você acertou!"></CalloutSucess>
                    }

                    <BoxFooter>
                        {this.props.resp['sequencia'] > 1
                            && <ButtonDefault onClick={this.handle_anterior} align_class="margin-r-5"><i className="fa fa-chevron-left" /> Questão Anterior</ButtonDefault>
                        }

                        {this.props.resp['sequencia'] === 1
                            && <ButtonDefault disabled align_class="margin-r-5"><i className="fa fa-chevron-left" /> Questão Anterior</ButtonDefault>
                        }

                        {(this.props.resp['sequencia'] < this.props.resp['qt_quest'] && this.props.resp['dh_fim'])
                            && <ButtonDefault onClick={this.handle_proximo} align_class="margin-r-5">Próxima Questão <i className="fa fa-chevron-right" /></ButtonDefault>
                        }
                        {(this.props.resp['sequencia'] >= this.props.resp['qt_quest'] && this.props.resp['dh_fim'])
                            && <ButtonDefault disabled align_class="margin-r-5">Próxima Questão <i className="fa fa-chevron-right" /></ButtonDefault>
                        }

                        {(!this.props.resp['dh_fim'])
                            && <ButtonDefault onClick={this.handle_confirmar} align_class="margin-r-5">Confirmar Resposta</ButtonDefault>
                        }
                        {this.props.resp['id_aula'] && this.props.goAula &&
                            <ButtonDefault align_class="pull-right margin-r-5" >
                                <Link to={`/minha-aula-show/${this.props.resp['id_inscricao']}/${this.props.resp['id_aula']}`}>Ir para Aula<i className="fa fa-quest"></i></Link>
                            </ButtonDefault>
                        }


                    </BoxFooter>
                    <BoxFooter>
                        <div className="text-center">
                            {pode_finalizar
                                && <ButtonWarning align_class="btn-lg"
                                    onClick={this.handle_finalizar} >Finalizar {quiz ? 'Quiz' : 'Questionário'}</ButtonWarning>
                            }

                            {pode_refazer && !quiz
                                && <ButtonDanger onClick={() => modal_open('nova-tentativa-dlg')} align_class="pull-right">Fazer Nova Tentativa</ButtonDanger>
                            }

                            {pode_refazer && quiz
                                && <ButtonWarning onClick={() => modal_open('nova-tentativa-quiz-dlg')} align_class="pull-right">Refazer o Quiz</ButtonWarning>
                            }


                        </div>
                    </BoxFooter>
                </BoxBody>
            </BoxWidget>
        )
    }


    render() {
        return (
            <>
                {this.render_quest()}
                {!this.props.quiz && this.nova_tentativa_dlg()}
                {this.props.quiz && this.nova_tentativa_quiz_dlg()}
            </>
        )
    }
}

const mapStateToProps = state => ({
    resp: state.resp,
})
const mapDispatchToProps = dispatch => bindActionCreators({
    obter, finished, check_uncheck, confirmar, anterior, proximo,
    finalizar, finalizar_reload_aula, refazer_quest
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(QuestRespShow)

