import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { listar } from './quest-lista-action'
import {date_time_loc} from '../sys/normalize-location'

import PageSetup from '../main/page-setup'
import { ContentSection, Row} from '../common/layout'
import CardSecond, {Item} from '../common/widget/card-second'
import {BadgeAqua, BadgeGreen, BadgeRed} from '../common/ui/badge'
import { CalloutInfo } from '../common/msg/callout'


class RespLista extends Component {

    componentDidMount() {
        this.props.listar()
    }

    renderRespostar(dados) {
        if (dados.length === 0) {
            return (
                <CalloutInfo title="Você ainda não tem questionário respondido!">
                </CalloutInfo>
            )
        }

        return dados.map(vo => {
            return(

                <CardSecond
                    key={'resp'+vo['id_resp']} 
                    title={vo['titulo']}
                    icon={'fa fa-question-circle fa-4x'}
                    col_class="col-md-4"
                    color_class='bg-aqua'
                    minHeightHeader={'110px'}
                    route={`/quest-resp/${vo['id_resp']}`}
                >
                    <Item text="Situação/Ref./Nota">
                        <BadgeAqua title={vo['nota'] || '-'}/>
                        <BadgeAqua title={vo['referencia'] || '?'}/>
                        { (vo['sit_resp'] === '1') && <BadgeAqua title={vo['situacao']}/>}
                        { (vo['sit_resp'] === '2') && <BadgeRed title={vo['situacao']}/>}
                        { (vo['sit_resp'] === '4') && <BadgeGreen title={vo['situacao']}/>}
                    </Item>
                    <Item text="Inicio">
                        <BadgeAqua title={date_time_loc(vo['dh_inicio']) || '?'}/>
                    </Item>
                    <Item text="Fim">
                        <BadgeAqua title={date_time_loc(vo['dh_fim']) || '?'}/>
                    </Item>
                </CardSecond>

            )
        })
    }

    render_view() {
        return(
            <PageSetup title='Questionários' subtitle='Respostas de questionários'>
                <ContentSection>
                    <Row>
                        {this.renderRespostar(this.props.respostas.lista)}
                    </Row>

                </ContentSection>

            </PageSetup>
        )
    }

    render() {
        return this.render_view()
    }

}

const mapStateToProps = state => ({respostas: state.respostas})
const mapDispatchToProps = dispatch => bindActionCreators({listar}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(RespLista)
