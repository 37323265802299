export const date_loc = value => {
    if (!value) 
      return value

    const dt = value.substr(0,10).split('-')
    return `${dt[2]}/${dt[1]}/${dt[0]}`
}

export const date_time_loc = value => {
  if (!value) 
    return value

  const t = value.split(' ')

  const dt = t[0].split('-')
  return `${dt[2]}/${dt[1]}/${dt[0]} ${t[1] ?? ''}`
}


const formatter_money = new Intl.NumberFormat([], {
  style: 'currency',
  currency: 'BRL'
})

export const money_loc = value => {  
  return formatter_money.format(!isNaN(value) ? value: 0)
}
