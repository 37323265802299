import React from 'react'

export default function ProgressBar(props) {


    return (
        <div className="progress">
            <div className={`progress-bar progress-bar-${props.color || 'aqua'}`} style={{ width: `${props.percent || '100'}%` }}><span >{props.percent || '0'}% {props?.title}</span></div>
        </div>
    )
}


