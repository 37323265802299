import React from 'react'
import { NavLink } from 'react-router-dom'
import { withRouter } from "react-router-dom";

class MenuItem extends React.Component {


    getActiveClass (route)  {
        return this.props.location.pathname === route ? 'active' : '';
    }

    render() {
        return (
            <li className={this.getActiveClass(this.props.route)}>
                <NavLink to={ this.props.route || '/' } onClick={this.props.onClick}><i className={this.props.icon ||'fa fa-link'}></i> <span>{this.props.label || 'Não Informado'}</span></NavLink>
            </li>
        )

    }    

}

export default withRouter(MenuItem);