import act from '../main/action-const'

const INITIAL_STATE = {
    dados: {},
    lista: [],
    perguntas: [],
    id_pergunta: 0,
    upd_status: 0, // 0 n/a, 1 localizou, 2 não localizou, 3 salvo, 4 carregado, 5 incluido
}

export default function (state = INITIAL_STATE, action) {

    switch (action.type) {

        case act.MEU_CURSO_AULAS_LISTADAS:
            return {
                ...state, lista: action.payload,
                upd_status: 0
            }


        case act.MEU_CURSO_AULAS_CONTADOR_LISTADOS: {
            const dados = action.payload || [];
            const lista_atual = state.lista;
            const nova = lista_atual.map( item => {
                for (const ct of dados) {
                    if (ct['id_aula'] === item['id_aula']) {
                        item['qt_pendente'] = ct['qt_pendente']
                        item['qt_comentado'] = ct['qt_comentado']
                        item['qt_alerta'] = ct['qt_alerta']
                        item['qt_alerta_ok'] = ct['qt_alerta_ok']
                        item['qt_diamantes'] = ct['qt_diamantes']
                    }
                }
                return item
            })

            return {
                ...state, lista: nova,
                upd_status: 0
            }
        }

        case act.MEU_CURSO_AULA_CARREGADA:
            return { ...state, perguntas: [], dados: action.payload, upd_status: 4 }

        case act.AULA_SALVA:
            return {
                ...state, dados: action.payload,
                upd_status: 3
            }

        case act.MEU_CURSO_AULAS_PERGUNTA_LISTADAS:
            return {
                ...state, perguntas: action.payload,
            }

        case act.MEU_CURSO_AULAS_PERGUNTA_SET:
            return {
                ...state, id_pergunta: action.payload,
            }

        default:
            return state
    }
}
