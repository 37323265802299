
export const encodeBase64 = (sString) => {
    return window.btoa(unescape(encodeURIComponent( sString || '' )));
}

export const decodeBase64 = (sBase64) => {
    try {
        return decodeURIComponent(escape(window.atob( sBase64 || '' )));    
    } catch (error) {
        return ''
    }
}