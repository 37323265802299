import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
    clear, obter, check_uncheck, confirmar, 
    anterior, proximo
} from '../quest-resp/resp-action'
import { decodeBase64 } from '../sys/base64'


import PageSetup from '../main/page-setup'
import { ContentSection, Row, Grid } from '../common/layout'
import { BoxWidget, BoxBody } from '../common/widget/box'

import config from '../main/config'
import './resp.css'
import QuestRespShow from './quest-resp-show'

class Resp extends Component {

    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.clear()
        this.props.obter(params['id_resp'])
    }

    render_info() {
        const cfg = config()

        return (
            <BoxWidget
                title={this.props.resp['titulo']}
                subtitle={this.props.resp['proposito']}>
                <BoxBody>
                    <img className="img-responsive pad" src={cfg.CDN_PATH + 'quest.png'} alt="Questionario" />
                    <h4><b>Visão Geral</b></h4>
                    <div dangerouslySetInnerHTML={{ __html: decodeBase64(this.props.resp['visao_geral']) }}></div>
                </BoxBody>
            </BoxWidget>
        )
    }

    render() {
        return (
            <PageSetup title={
                this.props.resp['id_resp'] === '4' ?
                    'Questionário Finalizado' :
                    'Responda o Questionário'
            } subtitle=''>
                <ContentSection>
                    <Row>
                        <Grid cols="md-6">
                            <QuestRespShow goAula={true}/>

                        </Grid>
                        <Grid cols="md-6">
                            {this.render_info()}
                        </Grid>
                    </Row>
                </ContentSection>
            </PageSetup>
        )
    }
}

const mapStateToProps = state => ({
    resp: state.resp,
})
const mapDispatchToProps = dispatch => bindActionCreators({
    clear, obter, check_uncheck, confirmar, 
    anterior, proximo
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Resp)

