import React from 'react'
//import { Anchor } from '../helper'

export default props => (

  <footer className="main-footer">
    {/* To the right */}
    <div className="pull-right hidden-xs">
      Aplicações para a educação.
    </div>
    {/* Default to the left 
    <strong>Copyright &copy; {props.year} <Anchor href="https://edumax.net.br">EduMax</Anchor>.</strong> Todos os direitos reservados.
  */}
    <strong>Copyright &copy; {props.year} EduMax.</strong> Todos os direitos reservados.
  </footer>

)