import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Anchor } from '../common/helper'

import { go_esqueci, recover_password } from './login-actions'
import config from '../main/config'

import { Row, Grid } from '../common/layout'
import { CalloutInfo, CalloutDanger } from '../common/msg/callout'
import { Input } from '../common/form'


class LoginEsqueci extends Component{

    componentDidMount() {
        document.body.classList.remove( config().THEME_NAME )
        document.body.classList.remove('sidebar-mini')
        document.body.classList.add('login-page')
    }

    componentWillUnmount() {
        document.body.classList.remove('login-page')
        document.body.classList.add( config().THEME_NAME )
        document.body.classList.add('sidebar-mini')
    }

    onSubmit(values) {
        const { recover_password } = this.props
        recover_password(values)
    }

    render_form() {
        const { handleSubmit } = this.props
        return (
            <>
                <p className="login-box-msg">Informe o e-mail e confirme.</p>

                <form onSubmit={handleSubmit( v => this.onSubmit(v))}>
                    <div className={`form-group`}>
                        <Field component={Input} type="email" name="email" placeholder="Email" />
                        <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
                    </div>
                    <Row>
                        <Grid cols="md-12">
                            <button type="button" className="btn btn-danger" onClick={()=>{this.props.go_esqueci(0)}}><i className="fa fa-close"></i></button>
                            <button type="submit" className="btn btn-primary pull-right">Confirmar</button>
                        </Grid>
                    </Row>
                </form>
            </>
        )

    }

    render_enviado() {
        return (
            <CalloutInfo>
                Se você estiver regularmente registrado com o e-mail informado,
                em breve você receberá uma mensagem com instruções para registrar uma nova senha.
                Fique atento a sua caixa de entrada e verifique também anti-spam.
                <button type="button" className="btn btn-default" onClick={()=>{this.props.go_esqueci(0)}}>Finalizar</button>
            </CalloutInfo>
        )
    }

    render_falhou() {
        return (
            <CalloutDanger>
                Ops! A tentativa de encaminhar o e-mail de recuperação de senha falhou. Por favor, tente mais tarde.
                <br /><button type="button" className="btn btn-default" onClick={()=>{this.props.go_esqueci(0)}}>Finalizar</button>
                <br />
            </CalloutDanger>
        )
    }
    

    render() {


        return (
        <div className="login-box">
            <div className="login-logo">
                <Anchor href="/">{config().NOME_SISTEMA || 'Nome Sistema'}</Anchor>
            </div>
            <div className="login-box-body">

                <h3 className="login-box-msg">Esqueci minha Senha</h3>

                {this.props.login_info['login_esqueci'] === 1 && this.render_form() }

                {this.props.login_info['login_esqueci'] === 2 && this.render_enviado() }

                {this.props.login_info['login_esqueci'] === 3 && this.render_falhou() }

            </div>
        </div>
        )
    }
}

LoginEsqueci = reduxForm({
    form: 'loginEsqueci'
})(LoginEsqueci)
const mapStateToProps = state => ({ login_info: state.login })
const mapDispatchToProps = (dispatch) => bindActionCreators({ go_esqueci, recover_password }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(LoginEsqueci)
