import { transmitir } from '../sys/transmitir'

import act from '../main/action-const'

const ROTA = 'pedido'

export const obter_pedidos = () => {
    return transmitir([], 'get', ROTA,
        (payload) => ({ type: act.PEDIDO_LISTADO, payload: payload || {} })
    )
}

export const obter_pedido_carrinho = (id_pedido) => {
    const data = [];
    data['id_pedido'] = id_pedido;
    return transmitir(data, 'get', ROTA,
        (payload) => ({ type: act.CARRINHO_LISTADO, payload: payload || {} })
    )
}
