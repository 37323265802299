import act from '../main/action-const'

export const appDataUpdate = (values) => {
    return dispatch => (
        dispatch([
            { type: act.APP_DATA_UPDATE, payload: values }
        ])
    )
}

