import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { salvar_resposta } from './minhas-aulas-action'

import Modal from '../common/ui/modal'


class MinhaAulaRespostaAdd extends Component {

    constructor(props) {
        super(props);
        this.state = { texto: '', tamanho: 0 }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(event) {
        this.setState({ texto: event.target.value, tamanho:  event.target.value.length});
    }

    handleSubmit(event) {
        this.props.salvar_resposta(
            this.props.minhas_aulas['dados']['id_inscricao'], 
            this.props.minhas_aulas['dados']['id_aula'], 
            this.props.minhas_aulas['id_pergunta'], 
            this.state['texto'])

        this.setState({ texto: '', tamanho: 0 });
        event.preventDefault();
    }


    render() {

        return (
            <form onSubmit={this.handleSubmit}>
                <Modal
                    id='minha-aula-resposta-add'
                    title='Resposta'
                    confirmLabel='Confirmar'
                    confirmButtonType='submit'
                    onConfirm={() => { return true }}
                    onClose={() => { return true }}
                >
                    <div className="form-group">
                        <textarea className="form-control" 
                            value={this.state.texto} 
                            onChange={this.handleChange} 
                            rows={4} 
                            maxLength={2000}
                        />
                    </div>
                    Tamanho: {this.state['tamanho']}/2000
                </Modal>
            </form>
        )
    }
}

const mapStateToProps = state => ({
    minhas_aulas: state.minhasAulas
})
const mapDispatchToProps = dispatch => bindActionCreators({ salvar_resposta }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(MinhaAulaRespostaAdd)

