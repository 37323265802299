import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import PageSetup from '../main/page-setup'
import { ContentSection, Row, Grid } from '../common/layout'
import { BigButtonWarning, ButtonDefault } from '../common/ui'

import ExtratoList from '../extrato/extrato-list'
import ExtratoContador from './extrato-contador'
import { listar, listar_mais, checkpag, gera_pag } from './extrato-action'
import Box from '../common/template/box'
import { money_loc } from '../sys/normalize-location'
import { CalloutInfo } from '../common/msg/callout'

class Extrato extends Component {

    componentDidMount() {
        this.props.listar([])
    }

    render_lancamentos(dados) {
        if (dados.length === 0)
            return

        return (<ExtratoList
            dados={dados}
            checkpag={(id_extrato) => (this.props.checkpag(id_extrato))}
        >
            {(this.props.extrato.tem_mais) &&
                <ButtonDefault onClick={() => {
                    this.props.listar_mais(this.props.extrato.page + 1, this.props.extrato.filtro)
                }}>Carregar mais...</ButtonDefault>
            }
        </ExtratoList>
        )
    }

    render_pag_resumo() {
        if (!this.props.extrato.resumo?.ct)
            return (
                <CalloutInfo title="Para pagar parcelas pendentes ou vencidas marque a coluna pagar.">
                </CalloutInfo>
            )


        return (
            <Box title="Resumo do Pagamento">
                <div className="col-xs-6">
                    <div className="table-responsive">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th style={{ width: '50%' }}>Valor:</th>
                                    <td>{money_loc(this.props.extrato.resumo?.valor)}</td>
                                </tr>
                                <tr>
                                    <th>Desconto:</th>
                                    <td>{money_loc(this.props.extrato.resumo?.desconto)}</td>
                                </tr>
                                <tr>
                                    <th>Liquido:</th>
                                    <td>{money_loc(this.props.extrato.resumo?.liquido)}</td>
                                </tr>
                                <tr>
                                    <th>Multa:</th>
                                    <td>{money_loc(this.props.extrato.resumo?.multa)}</td>
                                </tr>
                                <tr>
                                    <th>Mora:</th>
                                    <td>{money_loc(this.props.extrato.resumo?.mora)}</td>
                                </tr>
                                <tr>
                                    <th>Devido:</th>
                                    <td>{money_loc(this.props.extrato.resumo?.devido)}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-xs-6">
                    <BigButtonWarning
                    onClick={(e) => { this.props.gera_pag() }}>
                        Continuar para pagamento
                    </BigButtonWarning>
                </div>
            </Box>
        )
    }

    render_extrato() {
        return (
            <PageSetup title='Extrato Financeiro'>
                <ContentSection>
                    <Row>
                        <Grid cols='md-12'>
                            <ExtratoContador />
                            {this.render_lancamentos(this.props.extrato.lista)}
                            {this.render_pag_resumo()}
                        </Grid>
                    </Row>
                </ContentSection>
            </PageSetup>
        )
    }

    render() {
        if (this.props.extrato.upd_status === 5) {
            return <Redirect to="/pedidos" />
        }
        return this.render_extrato()
    }
}

const mapStateToProps = state => ({ extrato: state.extrato, login: state.login })
const mapDispatchToProps = dispatch => bindActionCreators({ listar, listar_mais, checkpag, gera_pag }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Extrato)
