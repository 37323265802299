import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { carregar_contadores } from './extrato-action'

import { Row } from '../common/layout'
import Smallbox from '../common/widget/small-box'
import { money_loc } from '../sys/normalize-location'

class ExtratoContador extends Component {

    componentDidMount() {
        this.props.carregar_contadores()
    }

    render() {
        const contador = this.props.extrato.contador ?? []
        return (
            <>
                <Row>

                    <Smallbox
                        icon="fa fa-feed"
                        value={money_loc(contador['total_lmes'] ?? 0)}
                        sup=""
                        title="Pagos"
                        add_class="bg-green"
                        cols="md-4"
                    />

                    <Smallbox
                        icon="fa fa-feed"
                        value={money_loc(contador['total_pmes'] ?? 0)}
                        sup=""
                        title="Pendentes"
                        add_class="bg-yellow"
                        cols="md-4"
                    />

                    <Smallbox
                        icon="fa fa-feed"
                        value={money_loc(contador['total_vmes'] ?? 0)}
                        sup=""
                        title="Vencidos"
                        add_class="bg-maroon"
                        cols="md-4"
                    />
                </Row>
            </>
        )
    }

}

const mapStateToProps = state => ({ extrato: state.extrato })
const mapDispatchToProps = dispatch => bindActionCreators({ carregar_contadores }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(ExtratoContador)

