import React from 'react'


export const Button = (props) => (
    <button type={props.type || "button"}  className={`btn btn-${props.btn_class || 'default'} ${props.align_class|| ''} margin-r-5`} { ...props } title={props.hint} >
        { props.icon && <i className={`margin-r-5 ${props.icon}`}></i>}
        { props.children }
    </button>
)

export const ButtonDefault = (props) => (
    <Button btn_class="default" {...props}>
        { props.children }
    </Button>
)

export const ButtonSucess = (props) => (
    <Button btn_class="success" {...props}>
        { props.children }
    </Button>
)

export const ButtonWarning = (props) => (
    <Button btn_class="warning" {...props}>
        { props.children }
    </Button>
)

export const ButtonInfo = (props) => (
    <Button btn_class="info" {...props}>
        { props.children }
    </Button>
)

export const ButtonPrimary = (props) => (
    <Button btn_class="primary" {...props}>
        { props.children }
    </Button>
)


export const ButtonDanger = (props) => (
    <Button btn_class="danger" {...props}>
        { props.children }
    </Button>
)
export const BigButtonWarning = (props) => (
    <Button btn_class="warning btn-lg" {...props}>
        { props.children }
    </Button>
)

export const BigButtonDefault = (props) => (
    <Button btn_class="default btn-lg" {...props}>
        { props.children }
    </Button>
)

export const BigButtonDanger = (props) => (
    <Button btn_class="danger btn-lg" {...props}>
        { props.children }
    </Button>
)

