import React from 'react'
import { BadgeGreen, BadgeYellow, BadgeRed, Badge } from '../common/ui/badge'

const strateg_sit = {
    '0': (left) => (<Badge left={left}>Não Definido</Badge>),
    '12': (left) => (<BadgeYellow left={left}>Aguardando Pagamento</BadgeYellow>),
    '22': (left) => (<BadgeGreen left={left}>Quitado</BadgeGreen>),
    '33': (left) => (<BadgeRed left={left}>Vencido</BadgeRed>),
    '99': (left) => (<Badge left={left}>Cancelado</Badge>),
}

export function pedido_status(sit, left) {
    const f_sit = strateg_sit[sit ?? '9']
    if (f_sit) {
        return f_sit(left)
    }
    return (<></>)
}
