import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { reducer as toastrReducer } from 'react-redux-toastr'

import AppReducer from '../main/app-reducer'
import LoginReducer from '../login/login-reducer'
import PerfilReducer from '../perfil/perfil-reducer'
import meusCursosReducer from '../meus-cursos/meus-cursos-reducer'
import proximoCursoReducer from '../proximo-curso/proximo-curso-reducer'
import minhasAulasReducer from '../minhas-aulas/minhas-aulas-reducer'
import respReducer from '../quest-resp/resp-reducer'
import respListaReducer from '../quest-resp/resp-lista-reducer'
import dashboardReducer from '../dashboard/dashboard-reducer'
import carrinhoReducer from '../inscricao/carrinho-reducer'
import pedidoReducer from '../inscricao/pedido-reducer'
import jornadaReducer from '../jornada/jornada-reducer'
import extratoReducer from '../extrato/extrato-reducer'

const rootReducer = combineReducers({
    app: AppReducer,
    login: LoginReducer,
    perfil: PerfilReducer,
    toastr: toastrReducer,
    form: formReducer,
    meusCursos: meusCursosReducer,
    proximoCurso: proximoCursoReducer,
    minhasAulas: minhasAulasReducer,
    resp: respReducer,
    respostas: respListaReducer,
    dash: dashboardReducer,
    carrinho: carrinhoReducer,
    pedido: pedidoReducer,
    jornada: jornadaReducer,
    extrato: extratoReducer,
})

export default rootReducer