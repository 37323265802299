import { transmitir } from '../sys/transmitir'

import act from '../main/action-const'

const ROTA = 'jornada'

export const obter_jornada = () => {
    return transmitir([], 'get', ROTA,
        (payload) => ({ type: act.JORNADA_LISTADA, payload: payload || [] })
    )
}


export const obter_quest = (id_inscricao) => {
    const vo = []
    vo['id_inscricao'] = id_inscricao;
    return transmitir(vo, 'get', ROTA + '-quest',
        (payload) => ({ type: act.JORNADA_ADD_QUEST, payload: { data: payload, id_inscricao } })
    )
}


export const obter_andamento = (id_inscricao) => {
    const vo = []    
    vo['id_inscricao'] = id_inscricao;
    return transmitir(vo, 'get', ROTA + '-andamento',
        (payload) => ({ type: act.JORNADA_ADD_ANDAMENTO, payload: { data: payload, id_inscricao } })
    )
}
