import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Anchor } from '../common/helper'

import { change_password, logout } from './login-actions'
import config from '../main/config'

import { Row, Grid } from '../common/layout'

class CancelPage extends Component{

    componentDidMount() {
        document.body.classList.remove( config().THEME_NAME )
        document.body.classList.remove('sidebar-mini')
        document.body.classList.add('login-page')
    }

    componentWillUnmount() {
        document.body.classList.remove('login-page')
        document.body.classList.add( config().THEME_NAME )
        document.body.classList.add('sidebar-mini')
    }

    onSubmit(values) {
        const { change_password } = this.props
        change_password(values)
    }

    render() {

        return (
        <div className="login-box">
            <div className="login-logo">
                <Anchor href="/">{config().NOME_SISTEMA || 'Nome Sistema'}</Anchor>
            </div>
            <div className="login-box-body">

                <h3 className="login-box-msg">Acesso Cancelado</h3>
                <p className="login-box-msg">O acesso deste usuário foi cancelado.</p>
                <p className="login-box-msg">Para mais informações entre em contato pelo número {config().FONE_OFICIAL}.</p>

                <Row>
                    <Grid cols="md-12">
                        <button type="button" className="btn btn-primary pull-right" onClick={this.props.logout}>Sair</button>
                    </Grid>
                </Row>


            </div>
        </div>
        )
    }
}

const mapStateToProps = state => ({ login_info: state.login })
const mapDispatchToProps = (dispatch) => bindActionCreators({ change_password, logout }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(CancelPage)
