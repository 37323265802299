import act from '../main/action-const'

const INITIAL_STATE = {
    dados: {},
    lista: [],
    aulas: [],
    upd_status: 0
}


export default function (state = INITIAL_STATE, action) {

    switch (action.type) {

        case act.PROXIMO_CURSO_LISTADOS:
            return {
                ...state, lista: action.payload,
                upd_status: 0
            }

        case act.PROXIMO_CURSO_CARREGADO:
            return { ...state, dados: action.payload, upd_status: 4 }

        case act.PROXIMO_CURSO_INSCRICAO:
            return { ...state, dados: action.payload, upd_status: 5 }

        case act.PROXIMO_CURSO_AULAS_LISTADAS:
            return {
                ...state, aulas: action.payload
            }

        default:
            return state
    }
}
