import React from 'react'
import { Link } from 'react-router-dom'
import { Grid } from '../layout'

export default props => (

    <Grid cols={props.cols || "md-4"}>
        <div className="info-box">
            <span className={`info-box-icon ${props.add_class}`}>
                {!props.route && <i className={props.icon}></i>}
                {props.route
                    && <Link style={{color: "inherit"}} to={props.route}>
                        <i className={props.icon}></i>
                    </Link>
                }
            </span>
            <div className="info-box-content">
                <span className="info-box-text">{props.title}</span>
                <span className="info-box-number">
                    {props.value}
                    {props.sup ? <sup style={{ fontSize: "20px" }}>{props.sup}</sup> : ''}
                </span>
            </div>
        </div>
    </Grid>
)
