import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'


import PageSetup from '../main/page-setup'
import { ContentSection, Row, Grid } from '../common/layout'

import ProximoCursoShow from './proximo-curso-show'

import { obter, listar_aulas } from './proximo-curso-action'
import ProximoCursoAulasList from './proximo-curso-aulas-list'

class ProximoCursoInfo extends Component {

    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.obter(params['id_curso'], params['id_grade_item'])
        this.props.listar_aulas(params['id_curso'] ?? 0)
    }

    render_info() {
        return (
            <PageSetup title='Próximo Curso'>
                <ContentSection>
                    <Row>
                        <Grid cols="md-6">
                            <ProximoCursoShow />
                        </Grid>
                        <Grid cols="md-6">
                            <ProximoCursoAulasList />
                        </Grid>
                    </Row>
                </ContentSection>
            </PageSetup>
        )
    }

    render() {
        if (this.props.proximo_curso.upd_status === 5) {
            return <Redirect to="/pedidos" />
        }
        return this.render_info()
    }
}

const mapStateToProps = state => ({
    proximo_curso: state.proximoCurso,
})
const mapDispatchToProps = dispatch => bindActionCreators({ obter, listar_aulas }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(ProximoCursoInfo)

