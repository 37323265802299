import { initialize } from 'redux-form'
import { transmitir } from '../sys/transmitir'

import act from '../main/action-const'

const VALORES_INICIAIS = {}
const ROTA = 'extrato'

export const listar = (dados) => {
    return transmitir([], 'get', ROTA,
        (payload) => ([
            { type: act.EXTRATO_LISTADO, payload: payload },
            pag_resumo()
        ])
    )
}

export const listar_mais = (page, filtro) => {
    filtro['page'] = page;
    return transmitir(filtro, 'get', ROTA,
        (payload) => ([
            { type: act.EXTRATO_LISTADO_MAIS, payload: payload },
            { type: act.EXTRATO_ADD_PAGE, payload: page }
        ])
    )
}

export const obter = (id_extrato) => {
    let vo = []
    vo['id_extrato'] = id_extrato
    return transmitir(vo, 'get', ROTA,
        (payload) => ({ type: act.EXTRATO_CARREGADO, payload: payload[0] })
    )
}


export const limpar = () => {
    return [{ type: act.EXTRATO_LIMPAR }]
}

export const init = () => {
    return [
        initialize('tabelaEditForm', VALORES_INICIAIS)
    ]
}

export const carregar_contadores = () => {
    return transmitir({}, 'get', ROTA + '-contador',
        (payload) => ([
            { type: act.EXTRATO_CARREGAR_CONTADOR, payload: payload },
        ])
    )
}

export const checkpag = (id_extrato) => {
    const payload = {}
    payload['id_extrato'] = id_extrato
    return transmitir(payload, 'post', ROTA + '-checkpag',
        (payload) => ([
            listar(),
        ]), '',true, true
    )
}


export const pag_resumo = () => {
    return transmitir({}, 'get', ROTA + '-pg-resumo',
        (payload) => ([
            { type: act.EXTRATO_PAG_RESUMO_CARREGAR, payload: payload }
        ])
    )
}

export const gera_pag = () => {
    return transmitir({}, 'post', ROTA + '-gera-pag',
        (payload) => ([
            { type: act.EXTRATO_PAG_FATURAR }
        ])
    )

}
