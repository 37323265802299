// exemplo: https://codesandbox.io/s/pm4zrq97vj

import React, { Component } from "react";
import Downshift from "downshift";
import axios from "axios";


export default class Autocomplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: []
        };

        this.fetch = this.fetch.bind(this);
        this.inputOnChange = this.inputOnChange.bind(this);
    }

    inputOnChange(event) {
        if ((!event.target.value) || (event.target.value.trim().length < 3)) {
            return;
        }
        this.fetch(event.target.value);
    }

    fetch(search) {
        const url_go = `${this.props.url}${search}`;
        axios.get(url_go).then(resp => {
            let payload = resp.data
            if (!Array.isArray(payload) && typeof (payload) !== 'object')
                payload = []
            
            this.setState({ list: payload });
        });
    }
/*
                onStateChange={({ inputValue }) => inputValue && this.props.input.onChange(inputValue)}
                selectedItem={this.props.input.value}
*/

    render() {
        const error = this.props.meta && this.props.meta.touched && this.props.meta.error

        return (
            <Downshift
                onChange={this.props.onSelected}
                itemToString={item => (item ? item[this.props.columnShow] : "")}
            >
                {({
                    selectedItem,
                    getInputProps,
                    getItemProps,
                    highlightedIndex,
                    isOpen,
                    inputValue,
                    getLabelProps
                }) => (


                        <div>

                            <div>

                                <div className={`input-group has-feedback ${error ? 'has-error' : ''}`}>
                                    {this.props.icon && <span className="input-group-addon"><i className={this.props.icon}></i></span>}

                                    <input {...this.props.input}
                                        className="form-control"
                                        id={this.props.name}
                                        autoComplete="off"
                                        {...getInputProps({
                                            placeholder: this.props.placeholder,
                                            onChange: this.inputOnChange
                                        })}
                                    />

                                </div>

                                {error && <span className="help-block has-error">{this.props.meta.error}</span>}
                            </div>

                            {isOpen ? (
                                <div className="box box-widget">
                                    <div className="box-footer">
                                        <ul className="nav nav-stacked">

                                            {(this.state.list || [])
                                                /*.filter(
                                                    item =>
                                                        !inputValue ||
                                                        (item[this.props.columnShow] || '')
                                                            .toLowerCase()
                                                            .includes(inputValue.toLowerCase())
                                                )*/
                                                .slice(0, 10)
                                                .map((item, index) => (
                                                    <li
                                                        {...getItemProps({ key: index, index, item })}
                                                        style={{
                                                            backgroundColor:
                                                                highlightedIndex === index ? "lightgray" : "white",
                                                            fontWeight: selectedItem === item ? "bold" : "normal"
                                                        }}
                                                    >
                                                        {item[this.props.columnShow] || ' '}
                                                    </li>
                                                ))}

                                        </ul>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    )}
            </Downshift>
        );
    }
}

