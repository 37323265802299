import act from '../main/action-const'

const INITIAL_STATE = { 
    nome: '',
    documento: '',
    celular: '',
    classe: '',
    cep: '',
    logradouro: '',
    numero: '',
    complemento: '',
    uf: '',
    bairro: '',
    cidade: ''
}

export default function(state = INITIAL_STATE, action) {

    switch (action.type) {

        case act.PERFIL_CARREGADO:
            return { ...state, ...action.payload }

        default:
            return state
    }
}
