import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Link } from 'react-router-dom'

import { obter } from './minhas-aulas-action'

import { BoxWidget, BoxFooterComments, BoxComments } from '../common/widget/box'
import { Anchor } from '../common/helper'

class AulaCursoList extends Component {


    carregar_aula(id_inscricao, id_aula) {
        this.props.obter(id_inscricao, id_aula)
    }

    constructor(props) {
        super(props)
        this.carregar_aula = this.carregar_aula.bind(this)
    }


    renderAulas(aulas) {
        const embedado_na_aula = this.props?.embedado
        const embedado_id_aula = this.props?.embedado_id_aula
        const go_load = this.carregar_aula
        return (aulas || []).map(function (au) {
            const curso_block = /\b0|\b2/.test(au['sit_inscricao'])
            return (
                <BoxComments key={'aula' + au['id_aula']}>
                    {!embedado_na_aula && au['unlock'] && !curso_block
                        && <Link to={`/minha-aula-show/${au['id_inscricao']}/${au['id_aula']}`}>
                            {au['modulo'] + '.' + au['aula'] + ' - ' + au['titulo']}
                        </Link>
                    }
                    {embedado_na_aula && (embedado_id_aula !== au['id_aula']) && au['unlock'] && !curso_block
                        && <Anchor style={{ cursor: 'pointer' }} onClick={(e) => { go_load(au['id_inscricao'], au['id_aula']) }}>
                            {au['modulo'] + '.' + au['aula'] + ' - ' + au['titulo']}
                        </Anchor>
                    }
                    {embedado_na_aula && (embedado_id_aula === au['id_aula']) && au['unlock'] && !curso_block
                        && <>
                            {au['modulo'] + '.' + au['aula'] + ' - ' + au['titulo']}
                        </>
                    }
                    {!au['unlock'] && !curso_block
                        && <span>{au['modulo'] + '.' + au['aula'] + ' - ' + au['titulo']}
                            <i className="fa fa-lock pull-right" title="Bloqueada"></i>
                        </span>
                    }
                    {au['unlock'] && curso_block
                        && <span>{au['modulo'] + '.' + au['aula'] + ' - ' + au['titulo']}
                            <i className="fa fa-warning pull-right" title="Bloqueada"></i>
                        </span>
                    }

                    {au['referencia_quest'] &&
                        <i style={{ color: '#c2c8c6' }} className="fa fa-question-circle pull-right margin-r-5" title="Tem questionário"></i>
                    }

                    {(au['unlock'] && au['qt_pendente'] && au['qt_pendente'] !== '0') &&
                        <i className="fa fa-comment-o pull-right margin-r-5" title="Pergunta pendente"></i>
                    }
                    {(au['unlock'] && au['qt_comentado'] && au['qt_comentado'] !== '0') &&
                        <i className="fa fa-commenting-o pull-right margin-r-5" title="Pergunta comentada"></i>
                    }
                    {(au['unlock'] && au['qt_alerta'] && au['qt_alerta'] !== '0') &&
                        <i className="fa fa-comment pull-right margin-r-5" title="Pergunta para você"></i>
                    }
                    {(au['unlock'] && au['qt_alerta_ok'] && au['qt_alerta_ok'] !== '0') &&
                        <i className="fa fa-commenting pull-right margin-r-5" title="Pergunta para você respondida"></i>
                    }

                    {(au['qt_diamantes'] && parseInt(au['qt_diamantes']) > 0) &&
                        <i className="fa fa-diamond text-aqua pull-right margin-r-5" title="Pergunta valendo pontos"></i>
                    }

                </BoxComments>)
        }
        )
    }

    renderModulo(modulo) {
        return (
            <BoxWidget
                key={'modulo-' + modulo['id_aula']}
                title={/*modulo['modulo'] + ' - ' +*/ modulo['titulo']}
                subtitle=""
            >
                <BoxFooterComments>
                    {this.renderAulas(modulo['aulas'])}
                </BoxFooterComments>
            </BoxWidget>
        )
    }


    renderModuloList(lst) {
        const modulos = (lst || []).filter(vo => vo['aula'] === '0')

        for (let mo of modulos) {
            mo['aulas'] = (lst || []).filter(vo => vo['aula'] !== '0' && vo['modulo'] === mo['modulo'])
        }

        return modulos.map(mo => {
            return this.renderModulo(mo)
        })

    }

    render() {
        return (
            <>
                {this.renderModuloList(this.props.minhas_aulas.lista)}
            </>
        )
    }

}

const mapStateToProps = state => ({
    minhas_aulas: state.minhasAulas
})
const mapDispatchToProps = dispatch => bindActionCreators({ obter }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(AulaCursoList)

