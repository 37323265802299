import { initialize } from 'redux-form'
import { transmitir } from '../sys/transmitir'

import act from '../main/action-const'

const VALORES_INICIAIS = {
    titulo: '',
    modulo: 0
}

const ROTA = 'meu-curso-aula'

export const listar = (dados) => {
    let vo = []
    vo['id_inscricao'] = dados['id_inscricao']
    if (dados && dados['field'] && dados['value']) {
        vo[dados['field']] = dados['value']
    }
    return transmitir(vo, 'get', ROTA + '-list',
        (payload) => ([
            { type: act.MEU_CURSO_AULAS_LISTADAS, payload: payload },
            obter_contadores(dados['id_inscricao'] || 0)
        ])
    )
}

const obter_contadores = (id_curso) => {
    const vo = []
    vo['id_curso'] = id_curso
    return transmitir(vo, 'get', ROTA + '-contador',
        (payload) => ({ type: act.MEU_CURSO_AULAS_CONTADOR_LISTADOS, payload: payload })
    )
}

export const obter = (id_inscricao, id_aula) => {
    const vo = []
    vo['id_inscricao'] = id_inscricao
    vo['id_aula'] = id_aula
    return transmitir(vo, 'get', ROTA,
        (payload) => ([
            { type: act.MEU_CURSO_AULA_CARREGADA, payload: payload[0] || {} },
            quest_aula_get(id_inscricao, id_aula),
            perguntas_listar(id_inscricao, id_aula),
            listar({ id_inscricao: payload[0]['id_inscricao'] || 0 })
        ])
    )
}


export const concluir = (id_inscricao, id_aula) => {
    const vo = {
        id_inscricao,
        id_aula
    }
    return transmitir(vo, 'post', ROTA,
        (payload) => ([
            { type: act.MEU_CURSO_AULA_CARREGADA, payload: payload[0] || {} },
            quest_aula_get(id_inscricao, id_aula)
        ])
    )
}


export const iniciar_quest = (id_inscricao, id_aula, iniciar_quest) => {
    const vo = {
        'id_inscricao': id_inscricao,
        'id_aula': id_aula
    }
    return transmitir(vo, 'post', ROTA + '-iniciar_quest',
        (payload) => ([
            { type: act.MEU_CURSO_AULA_CARREGADA, payload: payload[0] || {} },
            quest_aula_get(id_inscricao, id_aula)
        ])
    )
}




export const concluir_curso = (id_inscricao, id_aula) => {
    const vo = {
        'id_inscricao': id_inscricao,
        'id_aula': id_aula
    }
    return transmitir(vo, 'post', ROTA + '-concluir-curso',
        (payload) => ({ type: act.MEU_CURSO_AULA_CARREGADA, payload: payload[0] || {} })
    )
}

export const concluir_recuperacao = (id_inscricao, id_aula) => {
    const vo = {
        'id_inscricao': id_inscricao,
        'id_aula': id_aula
    }
    return transmitir(vo, 'post', ROTA + '-concluir-recuperacao',
        (payload) => ({ type: act.MEU_CURSO_AULA_CARREGADA, payload: payload[0] || {} })
    )
}


export const atualizar_dados_local = (dados) => {
    return [{ type: act.AULA_ATUALIZAR_LOCAL, payload: dados }]
}

/*
export const salvar = (dados) => {
    return transmitir(dados, 'post', ROTA, 
        (payload)=> ([
            { type: act.AULA_SALVA, payload: payload }, 
            listar({id_curso: dados['id_curso']}),
            limpar()

        ])
    )
}
*/

export const listar_quest_curso = (id_aula) => {
    let vo = []
    vo['id_aula'] = id_aula
    return transmitir(vo, 'get', 'curso-quest-list',
        (payload) => ([
            { type: act.AULA_QUEST_CURSO_CARREGADA, payload: payload }

        ])
    )
}


export const listar_arquivo = (dados) => {
    return transmitir(dados, 'get', 'curso-aula-file-upload',
        (payload) => ([
            { type: act.AULA_ARQUIVO_LISTAR, payload: payload }

        ])
    )
}

export const pesquisar = (dados) => {
    let vo = []
    vo['titulo'] = dados['titulo']
    vo['subtitulo'] = dados['subtitulo']
    vo['codigo'] = dados['codigo']
    return transmitir(vo, 'get', ROTA, (payload) => ({ type: act.AULA_PESQUISADO, payload: payload[0] || {} }))
}

export const init = () => {
    return [
        initialize('moduloEditForm', VALORES_INICIAIS)
    ]
}

export const perguntas_listar = (id_inscricao, id_aula) => {
    let vo = []
    vo['id_inscricao'] = id_inscricao
    vo['id_aula'] = id_aula
    return transmitir(vo, 'get', ROTA + '-pergunta-list',
        (payload) => ({ type: act.MEU_CURSO_AULAS_PERGUNTA_LISTADAS, payload: payload })
    )

}

export const quest_aula_get = (id_inscricao, id_aula) => {
    const vo = []
    vo['id_inscricao'] = id_inscricao
    vo['id_aula'] = id_aula
    return transmitir(vo, 'get', ROTA + '-quest',
        (payload) => ({ type: act.RESP_CARREGADO, payload: payload[0] ?? {} })
    )
}


export const set_pergunta = (id_pergunta) => {
    return dispatch => (
        dispatch([
            { type: act.MEU_CURSO_AULAS_PERGUNTA_SET, payload: id_pergunta }
        ])
    )
}

export const salvar_pergunta = (id_inscricao, id_aula, texto, peso) => {
    let vo = {}
    vo['id_aula'] = id_aula
    vo['id_inscricao'] = id_inscricao
    vo['texto'] = texto
    vo['peso'] = peso
    return transmitir(vo, 'post', ROTA + '-pergunta',
        (payload) => ([
            perguntas_listar(id_inscricao, id_aula)
        ])
    )
}

export const salvar_resposta = (id_inscricao, id_aula, id_pergunta, texto) => {
    let vo = {}
    vo['id_pergunta'] = id_pergunta
    vo['texto'] = texto
    return transmitir(vo, 'post', ROTA + '-resposta',
        (payload) => ([
            perguntas_listar(id_inscricao, id_aula)
        ])
    )
}

export const like = (id_inscricao, id_aula, id_pergunta) => {
    let vo = {}
    vo['id_pergunta'] = id_pergunta
    return transmitir(vo, 'post', ROTA + '-pergunta-like',
        (payload) => ([
            perguntas_listar(id_inscricao, id_aula)
        ])
    )
}

export const atribuir_pontos = (id_inscricao, id_aula, id_pergunta, pontos) => {
    let vo = {}
    vo['id_pergunta'] = id_pergunta
    vo['pontos'] = pontos
    return transmitir(vo, 'post', ROTA + '-pergunta-pontos',
        (payload) => ([
            perguntas_listar(id_inscricao, id_aula)
        ])
    )
}