import React, { useState, useCallback, useEffect, useRef } from 'react'
import Cropper from 'react-easy-crop'
import { Row, Grid } from '../common/layout/'
import { ButtonInfo } from '../common/ui/buttons'
import { FileUpload } from '../common/ui/fileupload'
import { CalloutInfo } from '../common/msg/callout'

export default props => {
  const slide = useRef();
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
    //slide.current.setValue()
  }, [])

  useEffect(() => {
    if (window.jQuery('#crop-slide-image').destroy) {
      window.jQuery('#crop-slide-image').destroy()
    }
    slide.current = window.jQuery('#crop-slide-image').slider().on('slide', () => {
      setZoom(slide.current.getValue())
    }).data('slider')
  });

  return (
    <div>
      <Row>
        <Grid cols="md-3" />
        <div className="col-md-6" style={{ minHeight: props.minHeight || "400px" }}>
          {props.image &&
            <Cropper
              image={props.image}
              crop={crop}
              zoom={zoom}
              aspect={props.aspect}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          }
          {!props.image &&
            <CalloutInfo title= "Carregue uma imagem!">

              <FileUpload btn_class="success" label='Carregar Imagem'
                              mimeType={['image/png', 'image/jpeg']}
                              maxFileSize={1}
                              afterSelectFile={(data) => { props.save_handle(data) }}
              />

            </CalloutInfo>
          }
        </div>

      </Row>

      <Row>
        <Grid cols="md-3" />
        <Grid cols="md-6">
          <input id="crop-slide-image" type="text"
            value={zoom} className="slider form-control"
            data-slider-min={1} data-slider-max={3}
            data-slider-step={0.1} data-slider-value="[0]"
            data-slider-orientation="horizontal"
            data-slider-selection="before"
            data-slider-id="blue"
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </Grid>
      </Row>

      <Row>
          <Grid cols="md-3" />
          <Grid cols="md-3">
              <Row>
                <br />
                <ButtonInfo align_class="pull-left" onClick={() => { props.crop_cancelar_handle() }}><i className="fa fa-close" title="Cancelar"></i></ButtonInfo>
                <ButtonInfo align_class="pull-left" onClick={() => { props.crop_handle(croppedAreaPixels) }}>Salvar Imagem</ButtonInfo>
              </Row>
          </Grid>
      </Row>

    </div>
  )
}
