
const is_youtub = (link) => {
    return (/youtube.com|youtu.be/ig).test(link)

}

const normalize_link_youtube = (link) => {
    const endpoint = 'https://www.youtube.com/embed/'
    let match

    match = link.match(/youtube\.com\/embed\/(.*?)$/i)
    if (match)
        return endpoint +  match[1]

    match = link.match(/youtube\.com\/watch\?v=(.*?)&t=(.*?)$/i)
    if (match){
        return endpoint +  match[1]
    }

    match = link.match(/\?v=(.*?)&/i)
    if (match)
        return endpoint +  match[1]

    match = link.match(/youtube\.com\/watch\?v=(.*?)$/i)
    if (match)
        return endpoint +  match[1]

    match = link.match(/\?v=(.*?)$/i)
    if (match)
        return endpoint +  match[1]

    match = link.match(/\.be\/(.*?)$/i)
    if (match)
        return endpoint +  match[1]

    return link
}


const is_vimeo = (link) => {
    return (/vimeo.com/ig).test(link)
}


const normalize_link_vimeo = (link) => {
    const endpoint = 'https://player.vimeo.com/video/'
    let match

    match = link.match(/vimeo\.com\/(.*?)\/(.*?)$/i)
    if (match)
        return endpoint +  match[1]

    match = link.match(/vimeo\.com\/(.*?)$/i)
    if (match)
        return endpoint +  match[1]
    

    return link
}


const is_spreaker_com = (link) => {
    return (/spreaker/ig).test(link)
}


export const normalize_link_audio = (link) => {
    if (is_spreaker_com(link))
        return normalize_link_spreaker_com(link)

    return link
}


const normalize_link_spreaker_com = (link) => {
    const endpoint = 'https://widget.spreaker.com/player?episode_id='
    const config = '&theme=light&autoplay=false&playlist=false'
    const config_image = '&cover_image_url='
    let episode_id
    let image
    let match

    match = link.match(/"episode_id=(.*?)"/i)
    if (match)
        episode_id = match[1]

    match = link.match(/cover="(.*?)"/i)
    if (match)
        image = match[1]

    if (episode_id && image)
        return endpoint + episode_id + config + config_image + image;

    return link
}


export const normalize_link_video = (link) => {
    if (is_youtub(link))
        return normalize_link_youtube(link)

    if (is_vimeo(link))
        return normalize_link_vimeo(link)

    if (is_spreaker_com(link))
        return normalize_link_spreaker_com(link)

    return link
}




/*
<iframe src="https://player.vimeo.com/video/29249484" 
width="640" 
height="360" 
frameborder="0" 
allow="autoplay; fullscreen" 
allowfullscreen>

</iframe>
<p><a href="https://vimeo.com/29249484">IFLA 2011 San Juan: Open access and digital resources</a> from <a href="https://vimeo.com/ifla">IFLA</a> on <a href="https://vimeo.com">Vimeo</a>.</p>

*/