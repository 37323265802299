import React from 'react'
import { NavLink } from 'react-router-dom'

import { Anchor } from '../helper'
import { Button } from '../ui/buttons'


export default props => (
 
    <li className="dropdown user user-menu">
        <Anchor href="/" className="dropdown-toggle" data-toggle="dropdown">
            <img  src={props.user_image} className="user-image" alt="Usuário Foto" />
            <span className="hidden-xs">{ props.user_name || 'Anônimo' }</span>
        </Anchor>
        <ul className="dropdown-menu">
        <li className="user-header">
            <img src={props.user_image} className="img-circle" alt="Usuário Foto" />
            <p>
            { props.user_name || 'Anônimo' /*  - { props.user_role || 'observador' */}
            <small>Login em { props.user_dt_login || '00/00/0000 00:00'}</small>
            </p>
        </li>
        {/*  Menu Body  
        <li className="user-body">
            <div className="row">
            <div className="col-xs-4 text-center">
                <Anchor href="#">Followers</Anchor>
            </div>
            <div className="col-xs-4 text-center">
                <Anchor >Sales</Anchor>
            </div>
            <div className="col-xs-4 text-center">
                <Anchor >Friends</Anchor>
            </div>
            </div>
        </li>
        */}


        {/*  Menu Footer */}
        <li className="user-footer">
            <div className="pull-left">
            <NavLink to="/perfil-show" className="btn btn-default btn-flat">Perfil</NavLink>
            </div>
            {/*<div className="pull-left">
                <Button href="/" className="btn btn-default btn-flat" onClick={props.go_pwd_change}>Mudar Senha</Button>
            </div>*/}
            <div className="pull-right">
                <Button href="/" className="btn btn-default btn-flat" onClick={props.logout}>Sair</Button>
            </div>
        </li> 
        </ul>
    </li>
)

