import React from 'react'
import { Link } from 'react-router-dom'

import { Anchor } from '../helper'

export default props => (
    <div className={`${props.col_class || ''}`}>

        <div className="box box-primary">
            <div className="box-body box-profile">
                { props.route && 
                <Link to={props.route}>
                    <img className="profile-user-img img-responsive img-circle" src={`${props.image}`} alt="Foto" />
                </Link> }

                { !props.route && 
                    <img className="profile-user-img img-responsive img-circle" src={`${props.image}`} alt="Foto" />
                }

                <h3 className="profile-username text-center text-truncate" >{props.name || ''}</h3>

                <p className="text-muted text-center text-truncate">{props.title}</p>

                <ul className="list-group list-group-unbordered">
                    {props.children}
                </ul>
                { props.route &&
                    <Link to={props.route} className="btn btn-primary btn-block"><b>{props.action_title}</b></Link>
                }
            </div>
        </div>

    </div>

)

//style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow: 'ellipsis', width: '80%'}}
export const Item = props => (
    <li className="list-group-item" style={{overflow:'hidden'}}>
        {props.label && <b>{props.label}</b>} 
        {props.value && <Anchor className="pull-right">{props.value}</Anchor>}
        {props.children}
    </li>
)
