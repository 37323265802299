import React from 'react'

export const CalloutSucess = (props) => (
    <div className="callout callout-success" style={{ minHeight: props.minHeight || "10px" }}>
        { props.title && <h4>{props.title}</h4>}
        {props.children}
    </div>
)

export const CalloutWarning = (props) => (
    <div className="callout callout-warning" style={{ minHeight: props.minHeight || "10px" }}>
        { props.title && <h4>{props.title}</h4>}
        {props.children}
    </div>
)

export const CalloutInfo = (props) => (
    <div className="callout callout-info" style={{ minHeight: props.minHeight || "10px" }}>
        { props.title && <h4>{props.title}</h4>}
        {props.children}
    </div>
)

export const CalloutDanger = (props) => (
    <div className="callout callout-danger" style={{ minHeight: props.minHeight || "10px" }}>
        { props.title && <h4>{props.title}</h4>}
        {props.children}
    </div>
)

export const Callout = (props) => (
    <div className="callout" style={{ minHeight: props.minHeight || "10px" }}>
        { props.title && <h4>{props.title}</h4>}
        {props.children}
    </div>
)
