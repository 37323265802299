import React, { Component } from 'react'

export class Grid extends Component {

    toCssClasses(numbers) {
        const cols = numbers ? numbers.split(' ') : []
        let classes = ''
        for( let i in cols) {
            classes += 'col-' + cols[i] + ' '
        }

        return classes 
    }

    render() {
        const gridClasses = this.toCssClasses(this.props.cols || '')
        return (
            <div className={gridClasses}> 
                {this.props.children}
            </div> 
        )
    }
}