import { transmitir } from '../sys/transmitir'
import act from '../main/action-const'

const ROTA = 'perfil'

export const obter = () => {
    let vo = []
    return transmitir(vo, 'get', ROTA,
        (payload)=> ({ type: act.PERFIL_CARREGADO, payload: payload })
    )
}

export const crop_foto = (dados) => {
    return transmitir(dados, 'post', ROTA+'-foto-crop', 
        (payload)=> ([
            { type: act.PERFIL_FOTO_CROP, payload: payload['arquivo'] }
        ])
    )
}


