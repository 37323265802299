import React from 'react'
import { If } from '../helper'
import Autocomplete from './autocomplete'

const toCssClasses = (numbers) => {
    const cols = numbers ? numbers.split(' ') : []
    let classes = ''
    for (let i in cols) {
        classes += 'col-' + cols[i] + ' '
    }
    return classes
}


export const FormRow = props => (
    <div className="form-row">
        {props.children}
    </div>
)

export const FormGroup = props => {
    const error = props.meta.touched && props.meta.error
    const gridClasses = toCssClasses(props.cols || '')
    return (
        <div className={`form-group ${gridClasses} ${error ? 'has-error' : ''}`} >
            <If test={props.label}>
                <label className="control-label" htmlFor={props.name}>{props.label}</label>
            </If>

            {props.children}

            {error && <span className="help-block">{props.meta.error}</span>}
        </div>
    )
}


export const InputGroup = props => (
    <FormGroup {...props}>
        <input {...props.input}
            type={props.type || 'text'}
            placeholder={props.placeholder}
            className="form-control"
            autoComplete="off"
            id={props.name}
            disabled={props.disabled}
        />
    </FormGroup>
)


export const InputGroupAutocomplete = props => (
    <Autocomplete {...props} />
)



export const InputGroupAddon = props => {
    const error = props?.meta?.touched && props?.meta?.error
    return (
        <div>
            <div className={`input-group has-feedback ${error ? 'has-error' : ''}`}>
                {props.icon && <span className="input-group-addon"><i className={props.icon}></i></span>}
                <input {...props.input}
                    type={props.type || 'text'}
                    placeholder={props.placeholder}
                    className="form-control"
                    id={props.name}
                    autoComplete="off"
                    value={props.value}
                    onChange={props.onChange}
                />
            </div>
            {error && <span className="help-block has-error">{props?.meta?.error}</span>}
        </div>
    )
}

export const SelectGroupAddon = props => {
    const error = props?.meta?.touched && props?.meta?.error
    return (
        <div>
            <div className={`input-group has-feedback ${error ? 'has-error' : ''}`}>
                {props.icon && <span className="input-group-addon"><i className={props.icon}></i></span>}
                <select
                    {...props.input}
                    name={props.name}
                    className="form-control"
                    id={props.name}
                    onChange={props.onChange}
                    value={props.value}
                >
                    {props.children}
                </select>
            </div>
            {error && <span className="help-block has-error">{props?.meta?.error}</span>}
        </div>
    )
}



export const SelectGroup = props => (
    <FormGroup {...props}>
        <select
            {...props.input}
            name={props.name}
            className="form-control"
            id={props.name}
        >
            {props.children}
        </select>
    </FormGroup>
)


export const InputGroupAutocompleteAddon = props => (
    <Autocomplete {...props} />
)