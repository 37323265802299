import { transmitir } from '../sys/transmitir'

import act from '../main/action-const'

export const listar = () => {
    let vo = []
    return transmitir(vo, 'get', 'quest-resp-listar',
        (payload)=> ({ type: act.QUEST_RESPOSTA_LISTADO, payload: payload })
    )
}
