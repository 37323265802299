import act from '../main/action-const'

const INITIAL_STATE = {
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case act.CARRINHO_LISTADO:
            return {
                INITIAL_STATE, ...action.payload,
            }

        case act.CARRINHO_CLEAR:
            return {
                INITIAL_STATE,
            }


        default:
            return state
    }
}
