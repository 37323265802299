import { transmitir } from '../sys/transmitir'

import act from '../main/action-const'

const ROTA = 'config'

export const config_load = () => {
    return transmitir({}, 'get', ROTA,
        (payload)=> ({ type: act.CONFIG_LOAD, payload: payload })
    )
}

