import React from 'react'

import PageSetup from '../main/page-setup'
import { ContentSection } from '../common/layout'
import MeusCursosLista from '../meus-cursos/meus-cursos-lista'

export default props => (
    <PageSetup title='Home' subtitle='Seja bem-vindo.'>
        <ContentSection>            
            <MeusCursosLista/>
        </ContentSection>
    </PageSetup>
)