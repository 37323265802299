import act from '../main/action-const'

const INITIAL_STATE = { 
    dados: {},
    lista: [],
    pesquisa: []
}

export default function(state = INITIAL_STATE, action) {

    switch (action.type) {

        case act.QUEST_RESPOSTA_LISTADO:
            return {
                ...state, lista: action.payload,
                upd_status: 0
            }

        default:
            return state
    }
}
