import React from 'react'
import SimpleTable from '../common/tables/simple-table'
import { money_loc, date_time_loc, date_loc } from '../sys/normalize-location'

import { status_extrato } from './status-extrato'
import { BoxFooter } from '../common/widget/box'

const extrato_descr = (obterExtrato, row) => {
    return (
        <>
            {row['descricao'] || ''}
            {row['observacao'] && <> / {row['observacao']}</>}
            {row['comprovante'] && <><br />Comprovante: {row['comprovante']}</>}
            {row['cupom'] && <> / Cupom: {row['cupom']}</>}
            <br />{status_extrato(row['sit_lanc'] || '', true)}
        </>
    )
}

const check_pg = (row, checkpag) => {
    if (row['dh_liq'] || row['es'] !== 'E')
        return (<span>&nbsp;</span>)
    return (
        <input type="checkbox" defaultChecked={row['check']} onChange={()=>(checkpag(row['id_extrato']))} />
    )
}

export default props => {
    return (
        <SimpleTable
            title="Lançamentos do Extrato"
            columns={[
                { name: 'check', title: 'Pagar?', value: ((row) => check_pg(row, props.checkpag)) },
                { name: 'nome', title: 'Nome', value: ((row) => <>{row['nome']}<span className="clearfix" />{row['documento']}</>) },
                { name: 'descricao', title: 'Descrição', value: ((row) => { return extrato_descr(props.obterExtrato, row) }) },
                { name: '', title: 'Valor', value: ((row) => (row['es'] === 'E' ? 'D' : 'C')) },
                { name: 'valor', title: 'Valor', value: ((row) => { return money_loc(row['valor']) }) },
                { name: 'desconto', title: 'Desconto', value: ((row) => { return money_loc(row['desconto']) }) },
                { name: 'liquido', title: 'Liquido', value: ((row) => { return money_loc(row['liquido']) }) },
                { name: 'dt_venc', title: 'Vencto.', value: ((row) => { return date_loc(row['dt_venc']) }) },
                { name: 'mora', title: 'Mora', value: ((row) => { return money_loc(row['mora']) }) },
                { name: 'multa', title: 'Multa', value: ((row) => { return money_loc(row['multa']) }) },
                { name: 'devido', title: 'Devido', value: ((row) => { return money_loc(row['devido']) }) },
                { name: 'dh_liq', title: 'Liq.', value: ((row) => { return date_time_loc(row['dh_liq']) }) },
            ]}
            data={props.dados}
            row_key={(row) => ('id_extrato' + row['id_extrato'])}
        >
            <BoxFooter>
                {props.children}
            </BoxFooter>
        </SimpleTable>
    )
}
