import act from '../main/action-const'

const INITIAL_STATE = { 
    dados: {},
    lista: [],
}


export default function(state = INITIAL_STATE, action) {

    switch (action.type) {

        case act.MEUS_CURSOS_LISTADOS:
            return {
                ...state, lista: action.payload,
                upd_status: 0

            }

        case act.MEU_CURSO_CARREGADO:
            return { ...state, dados: action.payload, upd_status: 4 }

        default:
            return state
    }
}
