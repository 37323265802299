import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Anchor } from '../common/helper'
import Cookies from 'js-cookie'

import { change_password, logout, termo_aceito } from './login-actions'
import config from '../main/config'

function aceiteSave() {
    Cookies.set('aceite-termos-de-uso', true, {
        expires: 120
    })
}

class AgreePage extends Component {

    componentDidMount() {
        document.body.classList.remove(config().THEME_NAME)
        document.body.classList.remove('sidebar-mini')
        document.body.classList.add('login-page')
    }

    componentWillUnmount() {
        document.body.classList.remove('login-page')
        document.body.classList.add(config().THEME_NAME)
        document.body.classList.add('sidebar-mini')
    }

    onSubmit(values) {
        const { change_password } = this.props
        change_password(values)
    }

    render() {


        return (
            <div className="login-box">
                <div className="login-logo">
                    <Anchor href="/">{config().NOME_SISTEMA || 'Nome Sistema'}</Anchor>
                </div>
                <div className="login-box-body">

                    <h3 className="login-box-msg">Política de Privacidade e Termos de Uso</h3>
                    <p className="login-box-msg">Para continuar navegando na plataforma, é necessário fazer o aceite da nossa política de privacidade e termos de uso.</p>
                    <p className="login-box-msg">
                        <Anchor href="https://google.com" target="_blank" >Ler os Termos e Políticas</Anchor>
                    </p>
                    <p className="login-box-msg">
                        <button type="button" className="btn btn-primary" onClick={this.props.termo_aceito}>Concordar e Fechar</button>
                    </p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({ login_info: state.login })
const mapDispatchToProps = (dispatch) => bindActionCreators({ change_password, logout, termo_aceito }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(AgreePage)
