import { initialize } from 'redux-form'
import { transmitir } from '../sys/transmitir'

import act from '../main/action-const'

const VALORES_INICIAIS = {}
const ROTA = 'proximo-curso'

export const listar = (dados) => {
    let vo = []
    if (dados && dados['field'] && dados['value']) {
        vo[dados['field']] = dados['value']
    }
    return transmitir(vo, 'get', ROTA + '-list',
        (payload) => ({ type: act.PROXIMO_CURSO_LISTADOS, payload: payload })
    )
}


export const obter = (id_curso, id_grade_item) => {
    let vo = []
    vo['id_curso'] = id_curso
    vo['id_grade_item'] = id_grade_item
    return transmitir(vo, 'get', ROTA,
        (payload) => ({ type: act.PROXIMO_CURSO_CARREGADO, payload: payload[0] || {} })
    )
}


export const prep_inscricao = (id_curso, id_grade_item) => {
    const vo = {
        'id_curso': id_curso,
        'id_grade_item': id_grade_item
    }
    return transmitir(vo, 'post', ROTA + '-inscricao',
        (payload) => ({ type: act.PROXIMO_CURSO_INSCRICAO, payload: payload ?? {} })
    )
}

export const listar_aulas = (id_curso) => {
    let vo = []
    vo['id_curso'] = id_curso
    return transmitir(vo, 'get', ROTA + '-aulas-list',
        (payload) => ([
            { type: act.PROXIMO_CURSO_AULAS_LISTADAS, payload: payload }
        ])
    )
}



export const init = () => {
    return [
        initialize('cursoEditForm', VALORES_INICIAIS)
    ]
}
