import React from 'react'

import { BadgeAqua, BadgeRed, BadgeYellow, BadgeGreen, Badge } from '../common/ui/badge'

const strateg_sit = {
    '0': () => (<Badge>Cancelada</Badge>),
    '1': () => (<BadgeAqua>Em Andamento</BadgeAqua>),
    '2': () => (<Badge>Em Pausa</Badge>),
    '9': () => (<BadgeGreen>Finalizado</BadgeGreen>),
    '10': () => (<BadgeYellow>Em Recuperação</BadgeYellow>),
    '11': () => (<BadgeGreen>Aprovado</BadgeGreen>),
    '12': () => (<BadgeRed>Reprovado</BadgeRed>)
}

export function meuCursoSituacao(sit) {
    const f_sit_curso = strateg_sit[sit ?? '2']
    if (f_sit_curso) {
        return f_sit_curso()
    }
    return (<Badge>Em Pausa</Badge>)
}