import React from 'react'
import { toastr } from 'react-redux-toastr'

const onChangeFileHandler = (event, afterSelectFile, mimeType, maxFileSize) => {
    if (afterSelectFile 
    && event.target 
    && event.target.files 
    && event.target.files[0].name
    && checkFileSize(event, maxFileSize) 
    && checkMimeType(event, mimeType)
    ) {
        const data = new FormData() 
        data.append('fileupload', event.target.files[0], event.target.files[0].name)
        afterSelectFile(data)
        event.currentTarget.value = ''
    }
}


const checkFileSize = (event, maxFileSize) => {
    maxFileSize = maxFileSize || 5 // 5 mb é o default
    let files = event.target.files
    let size = (1024 * 1024) * maxFileSize 
    let ok = true
    for(let x = 0; x<files.length; x++) {
        if (files[x].size > size) {
            toastr.error('Ops!', files[x].name+' é muito grande, por favor reduza ou divida em arquivos com o máximo de '+maxFileSize+' Mb.')
            ok = false
        }
    }

    if (!ok) 
        event.target.value = null

    return ok;
}


const checkMimeType = (event, types) => {
    types = types || []
    let files = event.target.files
    let ok = true
    for(let x = 0; x < files.length; x++) {
        if (types.every(type => files[x].type !== type)) {
            toastr.error('Ops!', files[x].name+' não é de formato suportado para esta operação.')
            ok = false
        }
    }
    if (!ok)
        event.target.value = null 

   return ok;
}


export const FileUpload = (props) => (
    <>
        <label htmlFor="i-file-upload" className={`btn btn-${props.btn_class || 'default'}` }>{props.label || 'Enviar Arquivo'}</label>
        <input style={{opacity: 0, position: "absolute", pointerEvents: "none", width: "1px", height: "1px"}} 
            type="file" id="i-file-upload" name="fileupload"
            accept={props.mimeType} 
            onChange={(e)=>{onChangeFileHandler(e, props.afterSelectFile,  props.mimeType, props.maxFileSize )}}/>
    </>
)
