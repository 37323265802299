import act from '../main/action-const'
import Cookies from 'js-cookie'
import config from '../main/config'

const USER_KEY = '__user_key__'
const CFG_KEY = '__cfg_key__'

let login_store = localStorage.getItem(USER_KEY)
try {
    login_store = (JSON.parse(login_store))
} catch (e) {
    login_store = null
}

let aceite_termo
try {
    aceite_termo = Cookies.get('aceite-termos-de-uso')
} catch (e) {
    aceite_termo = false
}

let config_store = localStorage.getItem(CFG_KEY)
try {
    config_store = (JSON.parse(config_store))
} catch (e) {
    config_store = { 'skin': config().THEME_NAME }
}

const INITIAL_STATE = {
    usuario: login_store,
    token_valido: false,
    login_error: false,
    change_error: null,
    login_esqueci: 0,
    cfg: config_store,
    aceite_termo: aceite_termo
}

const config_save = (cfg) => {
    localStorage.setItem(CFG_KEY, JSON.stringify(cfg))
    document.body.classList.remove(config().THEME_NAME)
    config().THEME_NAME = cfg?.skin ?? 'skin-blue-light'
    document.body.classList.add(config().THEME_NAME)
}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case act.TOKEN_VALIDADO:
            if (action.payload) {
                return { ...state, token_valido: true, login_error: false }
            } else {
                localStorage.removeItem(USER_KEY)
                return { ...state, token_valido: false, usuario: null, login_error: false }
            }

        case act.USUARIO_CARREGADO:
            localStorage.setItem(USER_KEY, JSON.stringify(action.payload))
            return { ...state, usuario: action.payload, token_valido: true, login_error: false }

        case act.USUARIO_INVALIDO:
            localStorage.removeItem(USER_KEY)
            return { ...state, login_error: true }

        case act.USUARIO_TROCA_INVALIDA:
            return { ...state, change_error: action.payload }

        case act.GO_LOGIN_ESQUECI:
            return { ...state, login_esqueci: action.payload }

        case act.GO_USER_STATUS_CHANGE: {
            const info = state.usuario;
            info['status'] = action.payload
            return { ...state, usuario: info }
        }

        case act.PERFIL_FOTO_CROP: {
            const info = state.usuario;
            info['fotofile'] = action.payload

            localStorage.setItem(USER_KEY, JSON.stringify(info))

            return { ...state, usuario: info }
        }

        case act.ACEITE_REALIZADO:
            Cookies.set('aceite-termos-de-uso', true, {
                expires: 120
            })
            return { ...state, aceite_termo: true }

        case act.CONFIG_LOAD:
            config_save(action.payload)
            return { ...state, cfg: action.payload }


        default:
            return state
    }
}

